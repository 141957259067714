import React, { useEffect, useRef, useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { setShowSideNav } from '../../redux-store/redux-slices/settings'
import { getItem, setItem } from '../../utils/common-services/localstorage.services'
import { ROUTE_PATHS } from '../../utils/constants'
import {
  BlackjackIcon, CasinoIcon, CloseXIcon, GiftIcon,
  HistoryIcon, MenuIcon, RouletteIcon, SlotsIcon,
  SportsIcon, StarFillIcon, HomeIcon, AtomicRouletteIcon,
  FeedbackIcon, BecomePartnerIcon, LoyaltyProgramIcon,
  BlogIcon, LiveSupportIcon, PromotionIcon, SponsorshipIcon
} from '../../icons' // FireIcon ExclusivesIcon
// import LanguageSwitcher from '../Header/LanguageSwitcher'
import './sideNavBar.scss'
import { getAuthToken } from '../../utils/common-services/cookie.services'
import { setSelectedCasinoGameType } from '../../redux-store/redux-slices/Games/casinoGame'
import { CasinoGameTypeList } from './constant'

function SideNavbar () {
  const { t } = useTranslation('home')
  const dispatch = useDispatch()
  // const { showSideNav } = useSelector(state => state?.settings)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)
  const [isAsideOpen, setIsAsideOpen] = useState(false)
  const aslideref = useRef()
  const history = useHistory()
  const setWindowDimensions = () => {
    setWindowWidth(window.innerWidth)
    setWindowHeight(window.innerHeight)
  }

  const handleProtectedRoutes = (currentPath) => {
    if (getAuthToken()) {
      history.push(currentPath)
      return
    }
    const loginModal = new window.bootstrap.Modal(document.getElementById('loginModal'))
    loginModal.show()
  }

  const handleGameTypeSelection = (value) => {
    switch (value) {
      case CasinoGameTypeList.BACCARAT:
        dispatch(setSelectedCasinoGameType('Live Baccarat'))
        break
      case CasinoGameTypeList.SLOTS:
        dispatch(setSelectedCasinoGameType('Video Slots'))
        break
      case CasinoGameTypeList.GAME_SHOW:
        dispatch(setSelectedCasinoGameType('Game Show'))
        break
    }
  }

  // const sidenavExpand = () => {
  //   setItem('showSideNav', !showSideNav)
  //   dispatch(setShowSideNav(!showSideNav))
  //   document.getElementsByClassName('sidebar-wrap')[0]?.classList.toggle('sidebar-close')
  //   document.getElementsByClassName('page-container')[0]?.classList.toggle('sidebar-close')
  //   document.getElementsByClassName('header')[0]?.classList.toggle('sidebar-close')
  // }

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside (event) {
      if (aslideref.current && !aslideref.current.contains(event.target) && isAsideOpen) {
        console.log('Hello')
        setIsAsideOpen(false)
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [aslideref, isAsideOpen])

  useEffect(() => {
    dispatch(setShowSideNav(getItem('showSideNav')))
  }, [])

  useEffect(() => {
    window.addEventListener('resize', setWindowDimensions)
    return () => {
      window.removeEventListener('resize', setWindowDimensions)
    }
  }, [])

  useEffect(() => {
    if (windowWidth <= 768) {
      setItem('showSideNav', false)
      dispatch(setShowSideNav(false))
    }
  }, [windowHeight, windowWidth])

  return (
    <>
      {/* NEW SIDEBAR DESIGN START */}
      <aside ref={aslideref} className={isAsideOpen ? 'sidebar-open' : ''}>
        <div className='btn-wrap'>
          {/* //COMMENT THIS CODE FOR FUTURE REFERENCE// */}

          {/* <NavLink
            to={ROUTE_PATHS.HOME}
            className='btn custom-btn'
            isActive={(_, location) => {
              const isCasinoRoutes = !!location.pathname.match(ROUTE_PATHS.CASINO) || !!location.pathname.match(ROUTE_PATHS.CRASH_GAME) ||
                !!location.pathname.match(ROUTE_PATHS.DICE_GAME) || !!location.pathname.match(ROUTE_PATHS.DICE_GAME_DEMO) ||
                !!location.pathname.match(ROUTE_PATHS.MINE_GAME) || !!location.pathname.match(ROUTE_PATHS.GAME_CATAGORY) ||
                !!location.pathname.match(ROUTE_PATHS.FAV_CATEGORY) || !!location.pathname.match(ROUTE_PATHS.CASINO_PLAYGAME) ||
                !!location.pathname.match(ROUTE_PATHS.BLACKJACKGAME) || !!location.pathname.match(ROUTE_PATHS.ROULETTE) ||
                !!location.pathname.match(ROUTE_PATHS.RECENTLY_PLAYED)

              if (isCasinoRoutes || location.pathname === '/') {
                return true
              }
              return false
            }}
            onClick={() => setIsAsideOpen(false)}
          > */}
          <NavLink
            to={ROUTE_PATHS.CASINO}
            className='btn custom-btn custom-btn-color'
            isActive={(_, location) => {
              const isCasinoRoutes = !!location.pathname.match(ROUTE_PATHS.HOME) || !!location.pathname.match(ROUTE_PATHS.CRASH_GAME) ||
                !!location.pathname.match(ROUTE_PATHS.DICE_GAME) || !!location.pathname.match(ROUTE_PATHS.DICE_GAME_DEMO) ||
                !!location.pathname.match(ROUTE_PATHS.MINE_GAME) || !!location.pathname.match(ROUTE_PATHS.GAME_CATAGORY) ||
                !!location.pathname.match(ROUTE_PATHS.FAV_CATEGORY) || !!location.pathname.match(ROUTE_PATHS.CASINO_PLAYGAME) ||
                !!location.pathname.match(ROUTE_PATHS.BLACKJACKGAME) || !!location.pathname.match(ROUTE_PATHS.ROULETTE) ||
                !!location.pathname.match(ROUTE_PATHS.RECENTLY_PLAYED || !!location.pathname.match(ROUTE_PATHS.BACCARAT) || !!location.pathname.match(ROUTE_PATHS.SLOTS) ||
                  !!location.pathname.match(ROUTE_PATHS.GAMESHOW))

              if (isCasinoRoutes || location.pathname === '/casino') {
                return true
              }
              return false
            }}
            title='Casino'
            onClick={() => dispatch(setSelectedCasinoGameType('All'))}
          >
            <span className='icon'>
              <CasinoIcon />
            </span>
            <span className='text'>{t('sideNavBar.tabs.casino')}</span>
          </NavLink>
          <a href='#' className='btn custom-btn' title={t('sideNavBar.comingSoon')}>
            <span className='icon'>
              <SportsIcon />
            </span>
            <span className='text'>{t('sideNavBar.tabs.sports')}</span>
          </a>
          <div className='d-block d-md-none'>
            {/* <LanguageSwitcher /> */}
          </div>
        </div>
        <div className='sidenav-warp'>
          {/* <NavLink to={ROUTE_PATHS.CASINO} className='text-decoration-none' onClick={() => setIsAsideOpen(false)}>
            <h6 className='title-text'>{t('sideNavBar.tabs.casino')}</h6>
          </NavLink> */}
          <div className='sidenav-list'>
            <NavLink
              to={ROUTE_PATHS.CASINO}
              exact
              className='list-item'
              data-placement='top'
              data-toggle='tooltip'
              title='All Games'
              onClick={() => dispatch(setSelectedCasinoGameType('All'))}
            >
              <span className='icon'>
                <HomeIcon />
              </span>
              <span className='text'>All Games</span>
            </NavLink>

            <NavLink
              to={ROUTE_PATHS.FAV_CATEGORY}
              className='list-item'
              data-placement='top'
              data-toggle='tooltip'
              title={t('sideNavBar.comingSoon')}
              onClick={() => handleProtectedRoutes(ROUTE_PATHS.FAV_CATEGORY)}
            >
              <span className='icon'>
                <StarFillIcon />
              </span>
              <span className='text'>{t('sideNavBar.casinoTabsList.favorites')}</span>
            </NavLink>
            <NavLink
              to={ROUTE_PATHS.RECENTLY_PLAYED}
              className='list-item'
              data-placement='top'
              data-toggle='tooltip'
              title={t('sideNavBar.comingSoon')}
              onClick={() => handleProtectedRoutes(ROUTE_PATHS.RECENTLY_PLAYED)}
            >
              <span className='icon'>
                <HistoryIcon />
              </span>
              <span className='text'>{t('sideNavBar.casinoTabsList.recent')}</span>
            </NavLink>
            {/* <a href='#' className='list-item' data-placement='top' data-toggle='tooltip' title={t('sideNavBar.comingSoon')}>
              <span className='icon'>
                <ChallengesIcon />
              </span>
              <span className='text'>{t('sideNavBar.casinoTabsList.challenges')}</span>
            </a> */}
            {/* <a href='#' className='list-item' data-placement='top' data-toggle='tooltip' title={t('sideNavBar.comingSoon')}>
              <span className='icon'>
                <FireIcon />
              </span>
              <span className='text'>{t('sideNavBar.casinoTabsList.original')}</span>
            </a> */}
            {/* <a href='#' className='list-item' data-placement='top' data-toggle='tooltip' title={t('sideNavBar.comingSoon')}>
              <span className='icon'>
                <ExclusivesIcon />
              </span>
              <span className='text'>{t('sideNavBar.casinoTabsList.exclusives')}</span>
            </a> */}
            <NavLink to={ROUTE_PATHS.SLOTS} className='list-item' data-placement='top' data-toggle='tooltip' title={t('sideNavBar.comingSoon')} onClick={() => handleGameTypeSelection(CasinoGameTypeList.SLOTS)}>
              <span className='icon'>
                <SlotsIcon />
              </span>
              <span className='text'>{t('sideNavBar.casinoTabsList.slots')}</span>
            </NavLink>
            <NavLink to={ROUTE_PATHS.GAMESHOW} className='list-item' data-placement='top' data-toggle='tooltip' title={t('sideNavBar.comingSoon')} onClick={() => handleGameTypeSelection(CasinoGameTypeList.GAME_SHOW)}>
              <span className='icon'>
                <GiftIcon />
              </span>
              <span className='text'>{t('sideNavBar.casinoTabsList.shows')}</span>
            </NavLink>
            {/* <NavLink to={ROUTE_PATHS.POKER} className='list-item' data-placement='top' data-toggle='tooltip' title={t('sideNavBar.comingSoon')}>
              <span className='icon'>
                <LiveCasinoIcon />
              </span>
              <span className='text'>{t('sideNavBar.casinoTabsList.poker')}</span>
            </NavLink>
            <NavLink to={ROUTE_PATHS.DRAGONTIGER} className='list-item' data-placement='top' data-toggle='tooltip' title={t('sideNavBar.comingSoon')}>
              <span className='icon'>
                <LiveCasinoIcon />
              </span>
              <span className='text'>{t('sideNavBar.casinoTabsList.dragonTiger')}</span>
            </NavLink>
            <NavLink to={ROUTE_PATHS.SICBO} className='list-item' data-placement='top' data-toggle='tooltip' title={t('sideNavBar.comingSoon')}>
              <span className='icon'>
                <LiveCasinoIcon />
              </span>
              <span className='text'>{t('sideNavBar.casinoTabsList.sicBo')}</span>
            </NavLink>
            <NavLink to={ROUTE_PATHS.LIVELOBBY} className='list-item' data-placement='top' data-toggle='tooltip' title={t('sideNavBar.comingSoon')}>
              <span className='icon'>
                <LiveCasinoIcon />
              </span>
              <span className='text'>{t('sideNavBar.casinoTabsList.lobby')}</span>
            </NavLink> */}
            {/* <a href='#' className='list-item' data-placement='top' data-toggle='tooltip' title={t('sideNavBar.comingSoon')}>
              <span className='icon'>
                <FeatureIcon />
              </span>
              <span className='text'>{t('sideNavBar.casinoTabsList.buyIn')}</span>
            </a>
            <a href='#' className='list-item' data-placement='top' data-toggle='tooltip' title={t('sideNavBar.comingSoon')}>
              <span className='icon'>
                <EnhancedRTPIcon />
              </span>
              <span className='text'>{t('sideNavBar.casinoTabsList.rtp')}</span>
            </a>
            <a href='#' className='list-item' data-placement='top' data-toggle='tooltip' title={t('sideNavBar.comingSoon')}>
              <span className='icon'>
                <TableGamesIcon />
              </span>
              <span className='text'>{t('sideNavBar.casinoTabsList.tables')}</span>
            </a> */}

            {/* Blackjack Game */}
            <NavLink
              to={ROUTE_PATHS.ALLBLACKJACKGAME}
              className='list-item'
              data-placement='top'
              data-toggle='tooltip'
              title='Blackjack'
            >
              <span className='icon'>
                <BlackjackIcon />
              </span>
              <span className='text'>{t('sideNavBar.casinoTabsList.blackjack')}</span>
            </NavLink>

            {/* Baccarat Game */}
            <NavLink
              to={ROUTE_PATHS.BACCARAT}
              className='list-item'
              data-placement='top'
              data-toggle='tooltip'
              title={t('sideNavBar.comingSoon')}
              onClick={() => handleGameTypeSelection(CasinoGameTypeList.BACCARAT)}
            >
              <span className='icon'>
                <CasinoIcon />
              </span>
              <span className='text'>{t('sideNavBar.casinoTabsList.baccarat')}</span>
            </NavLink>

            {/* Roulette Game */}
            <NavLink
              to={ROUTE_PATHS.ALLROULETTE}
              className='list-item'
              data-placement='top'
              data-toggle='roulette'
              title='Roulette'
            >
              <span className='icon icon-roulette'>
                <RouletteIcon />
              </span>
              <span className='text'>{t('sideNavBar.casinoTabsList.roulette')}</span>
            </NavLink>

            {/* Atomic Roulette Game */}
            <NavLink
              to={ROUTE_PATHS.ATOMIC_ROULETTE}
              className='list-item'
              data-placement='top'
              data-toggle='atomic_roulette'
              title='Atomic-Roulette'
              onClick={() => setIsAsideOpen(false)}
            >
              <span className='icon'>
                <AtomicRouletteIcon />
              </span>
              <span className='text'><span style={{ color: 'red' }}>LIVE</span> {t('sideNavBar.casinoTabsList.atomicRoulette')}</span>
            </NavLink>

            <hr className='custom-hr' />

            {/* NOTE: COMMENT THIS FOR TEMPORARY USAGE */}
            {/* <a href='#' className='list-item' data-placement='top' data-toggle='tooltip' title={t('sideNavBar.comingSoon')} onClick={() => setIsAsideOpen(false)}>
              <span className='text'>{t('sideNavBar.casinoTabsList.sports')}</span>
            </a> */}
            {/* <NavLink to={ROUTE_PATHS.FEEDBACK} className='list-item' data-placement='top' data-toggle='tooltip' title='Feedback' onClick={() => setIsAsideOpen(false)}>
              <span className='text'>{t('sideNavBar.casinoTabsList.feedback')}</span>
            </NavLink> */}
            <NavLink
              to={ROUTE_PATHS.FEEDBACK} className='list-item' data-placement='top' data-toggle='tooltip' title='Feedback'
              onClick={() =>
                handleProtectedRoutes(ROUTE_PATHS.FEEDBACK)}
            >
              <span className='icon'>
                <FeedbackIcon />
              </span>
              <span className='text'>{t('sideNavBar.casinoTabsList.feedback')}</span>
            </NavLink>
            <NavLink
              to={ROUTE_PATHS.PARTNERPROGRAM} className='list-item' data-placement='top' data-toggle='tooltip' title='Partner Program'
              onClick={() => history.push(ROUTE_PATHS.PARTNERPROGRAM)}
            >
              <span className='icon'>
                <BecomePartnerIcon />
              </span>
              <span className='text'>{t('sideNavBar.casinoTabsList.partnerProgram')}</span>
            </NavLink>
            <a href='#' className='list-item' data-placement='top' data-toggle='tooltip' title={t('sideNavBar.comingSoon')} onClick={() => setIsAsideOpen(false)}>
              <span className='icon'>
                <LoyaltyProgramIcon />
              </span>
              <span className='text'>{t('sideNavBar.casinoTabsList.loyaltyProgram')}</span>
            </a>
            <a href='#' className='list-item' data-placement='top' data-toggle='tooltip' title={t('sideNavBar.comingSoon')} onClick={() => setIsAsideOpen(false)}>
              <span className='icon'>
                <BlogIcon />
              </span>
              <span className='text'>{t('sideNavBar.casinoTabsList.blog')}</span>
            </a>
            <a href='#' className='list-item' data-placement='top' data-toggle='tooltip' title={t('sideNavBar.comingSoon')} onClick={() => setIsAsideOpen(false)}>
              <span className='icon'>
                <LiveSupportIcon />
              </span>
              <span className='text'>{t('sideNavBar.casinoTabsList.liveSupport')}</span>
            </a>
            <a href='#' className='list-item' data-placement='top' data-toggle='tooltip' title={t('sideNavBar.comingSoon')} onClick={() => setIsAsideOpen(false)}>
              <span className='icon'>
                <PromotionIcon />
              </span>
              <span className='text'>{t('sideNavBar.casinoTabsList.promotions')}</span>
            </a>
            <a href='#' className='list-item' data-placement='top' data-toggle='tooltip' title={t('sideNavBar.comingSoon')} onClick={() => setIsAsideOpen(false)}>
              <span className='icon'>
                <SponsorshipIcon />
              </span>
              <span className='text'>{t('sideNavBar.casinoTabsList.sponsorships')}</span>
            </a>
          </div>
        </div>
      </aside>
      <div className='sidebar-ovrelay' />
      {/* NEW SIDEBAR DESIGN END */}
      <div className='mobile-bottom-bar d-md-none'>
        <ul className='mobile-nav-wrap mb-0 d-flex justify-content-center'>
          <li className='nav-item flex-0'>
            <span className='nav-link' onClick={() => setIsAsideOpen(!isAsideOpen)}>
              <span className='icon'>
                {isAsideOpen ? <CloseXIcon /> : <MenuIcon />}
              </span>
              <span className='text'>{t('sideNavBar.menu')}</span>
            </span>
          </li>
          {/* <li className='nav-item'>
            <a href='#' className='nav-link'>
              <span className='icon'>
                <SearchIcon />
              </span>
              <span className='text'>Search</span>
            </a>
          </li>
          <li className='nav-item'>
            <a href='#' className='nav-link'>
              <span className='icon'>
                <ChatIcon />
              </span>
              <span className='text'>Chat</span>
            </a>
          </li>
          <li className='nav-item'>
            <a href='#' className='nav-link'>
              <span className='icon'>
                <GameIcon />
              </span>
              <span className='text'>Games</span>
            </a>
          </li> */}
        </ul>
      </div>
    </>
  )
}

export default React.memo(SideNavbar)
