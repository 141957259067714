import { LOADER_HANDLER_TYPES, METHOD_TYPES } from '../../utils/constants'
import axiosInstanceService, { microServices } from '../apis'

export const getAllCasinoGames = (params) => {
  return axiosInstanceService(METHOD_TYPES.get, 'system/list-casino-games', {}, {
    server: microServices.USER_URL,
    params
  })
}

export const getCasinoProvidersService = (params) => {
  return axiosInstanceService(METHOD_TYPES.get, '/system/get-providers', {}, {
    server: microServices.USER_URL,
    params,
    loader: LOADER_HANDLER_TYPES.content
  })
}

export const getAllCasinoCategories = (params) => {
  return axiosInstanceService(METHOD_TYPES.get, 'system/list-casino-categories', {}, {
    server: microServices.USER_URL,
    params
  })
}

export const launchCasinoGameService = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, 'st8/launch', data, {
    server: microServices.USER_URL
    // headers: { 'demo-game': isDemoGame }
  })
}
