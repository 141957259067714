// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reward-statistic-page {
  display: flex;
  justify-content: space-between;
  color: #ffffff; }
  .reward-statistic-page .reward-data {
    width: 100%;
    font-size: 1.25rem;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center; }
    .reward-statistic-page .reward-data h3 {
      font-size: 1.5rem; }
    .reward-statistic-page .reward-data .reward-top-row {
      display: flex;
      justify-content: space-between;
      width: 80%; }
`, "",{"version":3,"sources":["webpack://./src/components/Statistics/statistics.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,cAAc,EAAA;EAHhB;IAMI,WAAW;IACX,kBAAkB;IAClB,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB,EAAA;IAZtB;MAeM,iBAAiB,EAAA;IAfvB;MAmBM,aAAa;MACb,8BAA8B;MAC9B,UAAU,EAAA","sourcesContent":[".reward-statistic-page {\n  display: flex;\n  justify-content: space-between;\n  color: #ffffff;\n\n  .reward-data {\n    width: 100%;\n    font-size: 1.25rem;\n    margin-top: 2rem;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    text-align: center;\n\n    h3 {\n      font-size: 1.5rem;\n    }\n\n    .reward-top-row {\n      display: flex;\n      justify-content: space-between;\n      width: 80%;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
