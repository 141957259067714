import { createSlice } from '@reduxjs/toolkit'
import { getCasinoProvidersList, getCasinoAllCategories, getCasinoGamesList, getUsersCasinoTransactions, getAllGames, getAllFavoriteGames, getCustomGameTransactions, getCasinoTransactions, getSportsBookTransactions, getMyCustomGameTransactions, getCustomGameHighRollerTransactions, getCustomGameDetails, getRecentlyPlayedGames } from '../redux/thunk/game'
import { DEFAULT_LIVE_STATS } from '../../utils/constants'

const initialState = {
  loading: false,
  providers: null,
  casinoCategoriesList: null,
  categoryWiseGamesList: [],
  favouriteGames: null,
  usersCasinoTransactions: null,
  viewAllGames: null,
  showAllFavGames: false,
  customfavorites: {},
  liveStatsData: DEFAULT_LIVE_STATS,
  customGameTransactions: null,
  myCustomGameTransactions: null,
  allCustomGames: null,
  recentlyPlayedGames: null
}

const {
  actions: {
    setFavouriteGame,
    setShowAllFavGames,
    setGamesList,
    setViewAllGames,
    setCustomFavGames,
    setUpdateSystemMyBet,
    setLiveStatsData,
    resetLiveStatsData,
    setAppendNewAllBets,
    setNewAllBets
  },
  reducer
} = createSlice({
  name: 'games',
  initialState: initialState,
  reducers: {
    setFavouriteGame: (state, action) => ({
      ...state,
      favouriteGames: action.payload
    }),
    setShowAllFavGames: (state, action) => ({
      ...state,
      showAllFavGames: action.payload
    }),
    setGamesList: (state, action) => ({
      ...state,
      categoryWiseGamesList: action?.payload
    }),
    setViewAllGames: (state, action) => ({
      ...state,
      viewAllGames: action?.payload
    }),
    setCustomFavGames: (state, action) => ({
      ...state,
      customfavorites: action?.payload
    }),
    setUpdateSystemMyBet: (state, action) => {
      const { latestBets } = action.payload
      const myTransactions = state.myCustomGameTransactions.bets?.length >= 10 ? { bets: [...latestBets, ...state.myCustomGameTransactions.bets].slice(0, -1) } : { bets: [...latestBets, ...state.myCustomGameTransactions.bets] }
      return ({
        ...state,
        myCustomGameTransactions: myTransactions
      })
    },
    setAppendNewAllBets: (state, action) => {
      const { latestBets } = action.payload
      const transactions = { bets: [...latestBets, ...state.customGameTransactions.bets].slice(0, -1) }
      return ({
        ...state,
        customGameTransactions: transactions
      })
    },
    setNewAllBets: (state, action) => {
      const wonPlacedBets = action.payload
      const uniqueIds = new Set(state.customGameTransactions.bets.map(bet => bet.id))
      const newBets = wonPlacedBets.filter(bet => !uniqueIds.has(bet.id))
      const updatedBets = newBets.length ? { bets: [...newBets, ...state.customGameTransactions.bets].slice(0, -1) } : { bets: [...state.customGameTransactions.bets] }
      return ({
        ...state,
        customGameTransactions: updatedBets
      })
    },
    setLiveStatsData: (state, action) => {
      const liveData = { ...state.liveStatsData }
      const { betAmount, winningAmount, result } = action.payload || {}
      if (!result || (result !== 'won' && result !== 'lost')) {
        return // Handle invalid result values
      }

      if (result === 'won') {
        liveData.wins = (liveData.wins || 0) + 1
      } else if (result === 'lost') {
        liveData.losses = (liveData.losses || 0) + 1
      }

      if (!isNaN(parseFloat(betAmount))) {
        liveData.wagered = (parseFloat(liveData.wagered || '0') + parseFloat(betAmount)).toFixed(2)
      }

      if (!isNaN(parseFloat(winningAmount)) && !isNaN(parseFloat(betAmount)) && result !== 'push') {
        const profitDelta = (parseFloat(winningAmount) - parseFloat(betAmount)).toFixed(2)
        liveData.profit = (parseFloat(liveData.profit || '0') + parseFloat(profitDelta)).toFixed(2)
      }
      return ({
        ...state,
        liveStatsData: liveData
      })
    },
    resetLiveStatsData: (state, action) => ({
      ...state,
      liveStatsData: action.payload
    })
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomGameDetails.fulfilled, (state, action) => {
        return {
          ...state,
          allCustomGames: action?.payload
        }
      })
      .addCase(getCasinoGamesList.pending, (state, action) => {
        return {
          ...state,
          loading: true
        }
      })
      .addCase(getCasinoGamesList.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          categoryWiseGamesList: action?.payload
        }
      })
      .addCase(getCasinoGamesList.rejected, (state, action) => {
        return {
          ...state,
          loading: false
        }
      })
      .addCase(getAllGames.pending, (state, action) => {
        return {
          ...state,
          loading: true
        }
      })
      .addCase(getAllGames.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          viewAllGames: action?.payload
        }
      })
      .addCase(getAllGames.rejected, (state, action) => {
        return {
          ...state,
          loading: false
        }
      })
      .addCase(getCasinoProvidersList.pending, (state, action) => {
        return {
          ...state,
          loading: true
        }
      })
      .addCase(getCasinoProvidersList.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          providers: action?.payload
        }
      })
      .addCase(getCasinoProvidersList.rejected, (state, action) => {
        return {
          ...state,
          loading: false
        }
      })
      .addCase(getCasinoAllCategories.fulfilled, (state, action) => {
        state.casinoCategoriesList = action?.payload
      })
      .addCase(getUsersCasinoTransactions.fulfilled, (state, action) => {
        return {
          ...state,
          usersCasinoTransactions: action?.payload
        }
      })
      .addCase(getAllFavoriteGames.pending, (state, action) => {
        return {
          ...state,
          loading: true
        }
      })
      .addCase(getAllFavoriteGames.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          favouriteGames: action?.payload
        }
      })
      .addCase(getAllFavoriteGames.rejected, (state, action) => {
        return {
          ...state,
          loading: false
        }
      })
      .addCase(getCustomGameTransactions.fulfilled, (state, action) => {
        return {
          ...state,
          customGameTransactions: action?.payload
        }
      })
      .addCase(getMyCustomGameTransactions.fulfilled, (state, action) => {
        return {
          ...state,
          myCustomGameTransactions: action?.payload
        }
      })
      .addCase(getCustomGameHighRollerTransactions.fulfilled, (state, action) => {
        return {
          ...state,
          customGameHighRollerTransactions: action?.payload
        }
      })
      .addCase(getCasinoTransactions.fulfilled, (state, action) => {
        return {
          ...state,
          casinoTransactions: action?.payload
        }
      })
      .addCase(getSportsBookTransactions.fulfilled, (state, action) => {
        return {
          ...state,
          sportsBookTransactions: action?.payload
        }
      })
      .addCase(getRecentlyPlayedGames.fulfilled, (state, action) => {
        return {
          ...state,
          recentlyPlayedGames: action?.payload
        }
      })
  }
})

export default reducer
export { setFavouriteGame, setShowAllFavGames, setGamesList, setViewAllGames, setCustomFavGames, setUpdateSystemMyBet, setLiveStatsData, resetLiveStatsData, setAppendNewAllBets, setNewAllBets }
