import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAnnouncementsService } from '../../../network/services/announcement.service'

export const getAnnouncements = createAsyncThunk(
  'fetch/announcements',
  async (params, thunkApi) => {
    try {
      const res = await getAnnouncementsService(params)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
