import { ReactComponent as AgeGroupIcon } from './age-group.svg'
import { ReactComponent as ArrowBottomIcon } from './arrow-bottom.svg'
import { ReactComponent as ArrowLeftIcon } from './arrow-left.svg'
import { ReactComponent as ArrowRightIcon } from './arrow-right.svg'
import { ReactComponent as ArrowTopIcon } from './arrow-top.svg'
import { ReactComponent as AnimationIcon } from './Animation.svg'
import { ReactComponent as BetlistIcon } from './betlist.svg'
import { ReactComponent as BellSolidIcon } from './bell-solid.svg'
import { ReactComponent as BlackjackIcon } from './blackjack.svg'
import { ReactComponent as BoltIcon } from './bolt.svg'
import { ReactComponent as CasinoIcon } from './casino.svg'
import { ReactComponent as ChallengesIcon } from './challenges.svg'
import { ReactComponent as ChatIcon } from './chat.svg'
import { ReactComponent as CloseXIcon } from './close-x.svg'
import { ReactComponent as CrashIcon } from './crash.svg'
import { ReactComponent as CupIcon } from './cup.svg'
import { ReactComponent as ClearIcon } from './Clear.svg'
import { ReactComponent as DiceIcon } from './dice.svg'
import { ReactComponent as DoubleIcon } from './double.svg'
import { ReactComponent as DangerIcon } from './danger.svg'
import { ReactComponent as EnhancedRTPIcon } from './enhanced-rtp.svg'
import { ReactComponent as ExclusivesIcon } from './exclusives.svg'
import { ReactComponent as FacebookIcon } from './facebook.svg'
import { ReactComponent as FeatureIcon } from './feature.svg'
import { ReactComponent as FireIcon } from './fire.svg'
import { ReactComponent as GameIcon } from './game.svg'
import { ReactComponent as GiftIcon } from './gift.svg'
import { ReactComponent as GoogleIcon } from './google.svg'
import { ReactComponent as GraphIcon } from './graph.svg'
import { ReactComponent as HeadphoneIcon } from './headphone.svg'
import { ReactComponent as HiddenIcon } from './hidden.svg'
import { ReactComponent as HistoryIcon } from './history.svg'
import { ReactComponent as HitIcon } from './hit.svg'
import { ReactComponent as InfoIcon } from './info.svg'
import { ReactComponent as KeyboardIcon } from './keyboard.svg'
import { ReactComponent as LeftArrowIcon } from './left-arrow.svg'
import { ReactComponent as LineIcon } from './line.svg'
import { ReactComponent as LiveCasinoIcon } from './live-casino.svg'
import { ReactComponent as LobbyIcon } from './lobby.svg'
import { ReactComponent as LogoutIcon } from './logout.svg'
import { ReactComponent as MaximizeIcon } from './maximize.svg'
import { ReactComponent as MenuIcon } from './menu.svg'
import { ReactComponent as MinimizeIcon } from './minimize.svg'
import { ReactComponent as RecycleIcon } from './recycle.svg'
import { ReactComponent as RedirectIcon } from './redirect.svg'
import { ReactComponent as RocketIcon } from './rocket.svg'
import { ReactComponent as RouletteIcon } from './roulette.svg'
import { ReactComponent as RightArrowIcon } from './right-arrow.svg'
import { ReactComponent as SIcon } from './s.svg'
import { ReactComponent as SearchIcon } from './search.svg'
import { ReactComponent as SettingIcon } from './setting.svg'
import { ReactComponent as SlotsIcon } from './slots.svg'
import { ReactComponent as SplitIcon } from './split.svg'
import { ReactComponent as SportsIcon } from './sports.svg'
import { ReactComponent as StandIcon } from './stand.svg'
import { ReactComponent as StarIcon } from './star.svg'
import { ReactComponent as StarFillIcon } from './star-fill.svg'
import { ReactComponent as TableGamesIcon } from './table-games.svg'
import { ReactComponent as TransactionsIcon } from './transactions.svg'
import { ReactComponent as TwitchIcon } from './twitch.svg'
import { ReactComponent as UserIcon } from './user.svg'
import { ReactComponent as UndoIcon } from './Undo.svg'
import { ReactComponent as VolumeIcon } from './volume.svg'
import { ReactComponent as WalletIcon } from './wallet.svg'
import { ReactComponent as HomeIcon } from './home.svg'
import { ReactComponent as ImageIcon } from './image.svg'
import { ReactComponent as DownIcon } from './down.svg'
import { ReactComponent as UpIcon } from './up.svg'
import { ReactComponent as HeaderSearchIcon } from './header-search.svg'
import { ReactComponent as TickIcon } from './Tick.svg'
import { ReactComponent as CalendawhiteIcon } from './calendawhite.svg'
import { ReactComponent as AtomicRouletteIcon } from './atomic_roulette.svg'
import { ReactComponent as Mute } from './volume-mute.svg'
import { ReactComponent as UnMute } from './volume-unmute.svg'
import { ReactComponent as MusicMute } from './music-mute.svg'
import { ReactComponent as MusicUnMute } from './music-unmute.svg'
import { ReactComponent as RewardIcon } from './reward.svg'
import { ReactComponent as FeedbackIcon } from './Feedback.svg'
import { ReactComponent as BecomePartnerIcon } from './BecomePartner.svg'
import { ReactComponent as LoyaltyProgramIcon } from './Loyalty.svg'
import { ReactComponent as BlogIcon } from './Blogging.svg'
import { ReactComponent as LiveSupportIcon } from './LiveSupport.svg'
import { ReactComponent as PromotionIcon } from './Promotion.svg'
import { ReactComponent as SponsorshipIcon } from './Sponsorship.svg'

export {
  UpIcon,
  DownIcon,
  AgeGroupIcon,
  ArrowBottomIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowTopIcon,
  AnimationIcon,
  BellSolidIcon,
  BetlistIcon,
  BlackjackIcon,
  BoltIcon,
  CasinoIcon,
  ChallengesIcon,
  ChatIcon,
  CloseXIcon,
  CrashIcon,
  CupIcon,
  ClearIcon,
  DiceIcon,
  DoubleIcon,
  DangerIcon,
  EnhancedRTPIcon,
  ExclusivesIcon,
  FacebookIcon,
  FeatureIcon,
  FireIcon,
  GameIcon,
  GiftIcon,
  GoogleIcon,
  GraphIcon,
  HeadphoneIcon,
  HiddenIcon,
  HistoryIcon,
  HitIcon,
  InfoIcon,
  KeyboardIcon,
  LeftArrowIcon,
  LineIcon,
  LiveCasinoIcon,
  LobbyIcon,
  LogoutIcon,
  MaximizeIcon,
  MenuIcon,
  MinimizeIcon,
  RecycleIcon,
  RedirectIcon,
  RocketIcon,
  RouletteIcon,
  RightArrowIcon,
  SIcon,
  SearchIcon,
  SettingIcon,
  SlotsIcon,
  SplitIcon,
  SportsIcon,
  StandIcon,
  StarIcon,
  StarFillIcon,
  TableGamesIcon,
  TransactionsIcon,
  TwitchIcon,
  UserIcon,
  UndoIcon,
  VolumeIcon,
  WalletIcon,
  HomeIcon,
  HeaderSearchIcon,
  ImageIcon,
  TickIcon,
  CalendawhiteIcon,
  AtomicRouletteIcon,
  Mute,
  UnMute,
  RewardIcon,
  MusicMute,
  MusicUnMute,
  FeedbackIcon,
  BecomePartnerIcon,
  LoyaltyProgramIcon,
  BlogIcon,
  LiveSupportIcon,
  PromotionIcon,
  SponsorshipIcon
}
