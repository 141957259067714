import { METHOD_TYPES } from '../../utils/constants'
import axiosInstanceService, { microServices } from '../apis'

export const placeBetAtomicRouletteGameService = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, 'atomic-roulette/place-bet', data, {
    server: microServices.USER_URL
  })
}

export const getLiveStreamingListingService = () => {
  return axiosInstanceService(METHOD_TYPES.get, 'system/host/list?isLiveStreamingActive=true', {}, {
    server: microServices.USER_URL
  })
}
export const getZegoCloudTokenService = () => {
  return axiosInstanceService(METHOD_TYPES.get, 'user/zego-token', {}, {
    server: microServices.USER_URL
  })
}

export const sendHostTipService = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, 'atomic-roulette/send-tip', data, {
    server: microServices.USER_URL
  })
}

export const getRoundTopWinsService = (data) => {
  return axiosInstanceService(METHOD_TYPES.get, 'atomic-roulette/top-wins', {}, {
    server: microServices.USER_URL,
    params: data
  })
}
export const getUserTopBetsService = (data) => {
  return axiosInstanceService(METHOD_TYPES.get, 'atomic-roulette/top-bets', {}, {
    server: microServices.USER_URL,
    params: data
  })
}
