import { LOADER_HANDLER_TYPES, METHOD_TYPES } from '../../utils/constants'
import axiosInstanceService, { microServices } from '../apis'

export const getAllBonusService = (params) => {
  return axiosInstanceService(METHOD_TYPES.get, '/bonus/list', {}, {
    server: microServices.SERVICE_URL_1,
    params,
    loader: LOADER_HANDLER_TYPES.page
  })
}

export const claimBonusService = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, '/bonus/claim-user-bonus', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: 'Bonus claimed successfully'
  })
}

export const getTotalRewardsService = (params) => {
  return axiosInstanceService(METHOD_TYPES.get, '/bonus/all-rewards-detail', {}, {
    server: microServices.SERVICE_URL_1,
    params,
    loader: LOADER_HANDLER_TYPES.page
  })
}

export const getSplittedBonusService = (params) => {
  return axiosInstanceService(METHOD_TYPES.get, '/bonus/get-split-bonus', {}, {
    server: microServices.SERVICE_URL_1,
    params
  })
}

export const claimAccumulatedBonusService = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, '/bonus/claim-all-bonus', data, {
    server: microServices.SERVICE_URL_1,
    successMessage: 'Bonus claimed successfully'
  })
}

export const settlementCommissionService = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, '/user/commision-settlement', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: 'Bonus claimed successfully'
  })
}

export const getAccumulatedBonusService = (data) => {
  return axiosInstanceService(METHOD_TYPES.get, '/bonus/claimable-list', data, {
    server: microServices.SERVICE_URL_1
  })
}
