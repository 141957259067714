import { combineReducers } from '@reduxjs/toolkit'
import authSlice from '../redux-slices/Auth/authSlice'
// import userSlice from '../redux-slices/Auth/userSlice'
import user from '../redux-slices/Users/user'
import gameSetting from '../redux-slices/gameSetting'
import crashGame from '../redux-slices/Games/crashGame'
import diceGame from '../redux-slices/Games/diceGame'
import blackjackGame from '../redux-slices/Games/blackjack'
import rouletteGame from '../redux-slices/Games/rouletteGame'
import atomicRouletteGame from '../redux-slices/Games/atomicRouletteGame'
import casinoGame from '../redux-slices/Games/casinoGame'
import games from '../redux-slices/games'
import settings from '../redux-slices/settings'
import wallet from '../redux-slices/walletSlice'
import strategy from '../redux-slices/strategy'
import sumsub from '../redux-slices/sumsub.slice'
import chat from '../redux-slices/chat.slice'
import bonus from '../redux-slices/bonus.slice'
import announcement from '../redux-slices/announcement.slice'

const rootReducer = combineReducers({
  authSlice,
  // userSlice,
  user,
  settings,
  gameSetting,
  crashGame,
  diceGame,
  blackjackGame,
  rouletteGame,
  casinoGame,
  games,
  wallet,
  strategy,
  sumsub,
  atomicRouletteGame,
  chat,
  bonus,
  announcement
})

export default rootReducer
