import { createSlice } from '@reduxjs/toolkit'
import { getAllChats } from '../redux/thunk/chat.thunk'

const initialState = {
  allChats: null
}

const {
  actions: {
    setAllChatsData
  },
  reducer
} = createSlice({
  name: 'chat',
  initialState,
  reducers: {

    setAllChatsData: (state, action) => {
      state.allChats = [...state.allChats, action.payload]
      // return {
      //   ...state,
      //   allChats: {
      //     ...state.allChats,
      //     messages: [...state.allChats, action.payload]
      //   }
      // }
    }

  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllChats.fulfilled, (state, action) => ({
        ...state,
        allChats: [...action.payload?.rows?.reverse()]
      }))
  }
})

export default reducer
export {
  setAllChatsData
}
