import { LOADER_HANDLER_TYPES, METHOD_TYPES } from '../../utils/constants/index'
import axiosInstance, { microServices } from '../apis/index'

/**
 * Service to get user info
 */
export const getUserInfoService = () => {
  return axiosInstance(METHOD_TYPES.get, 'user/user-detail', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table
  })
}

/**
 * Service to update user info
//  * @param {object} data - object contains name to be updated for user
 */
export const uploadProfilePhotoService = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'user/upload-profile-photo', data, {
    server: microServices.USER,
    loader: LOADER_HANDLER_TYPES.submit,
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: '*/*'
    }
  })
}
export const updateUserInfoService = (data) => {
  return axiosInstance(METHOD_TYPES.put, 'user/update-profile', data, {
    server: microServices.USER,
    loader: LOADER_HANDLER_TYPES.submit
  })
}

export const updatePhoneNumber = (data) => {
  return axiosInstance(METHOD_TYPES.put, 'user/update-phone', data, {
    server: microServices.USER,
    loader: LOADER_HANDLER_TYPES.submit
  })
}

export const generateQRCodeFor2fa = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'user/generate-secret-code', data, {
    server: microServices.USER,
    loader: LOADER_HANDLER_TYPES.submit
  })
}

export const firstTime2faAuthenticateToken = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'user/firstTime-2fa-authenticate-token', data, {
    server: microServices.USER,
    loader: LOADER_HANDLER_TYPES.submit
  })
}

export const deactivate2faAuthenticateTokenService = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'user/deactivate-two-factor-authentication', data, {
    server: microServices.USER,
    loader: LOADER_HANDLER_TYPES.submit
  })
}
export const getwithdrawTransactions = (data) => {
  return axiosInstance(METHOD_TYPES.get, 'user/getWithdrawTransaction', data, {
    server: microServices.USER,
    loader: LOADER_HANDLER_TYPES.submit
  })
}
export const getPlacedBets = (data, endpoint) => {
  return axiosInstance(METHOD_TYPES.get, endpoint, data, {
    server: microServices.USER,
    loader: LOADER_HANDLER_TYPES.submit,
    params: data

  })
}

export const getAllBets = (data, endpoint) => {
  return axiosInstance(METHOD_TYPES.get, endpoint, data, {
    server: microServices.USER,
    loader: LOADER_HANDLER_TYPES.submit,
    params: data
  })
}

export const getActiveJoiningBonusService = (data) => {
  return axiosInstance(METHOD_TYPES.get, 'bonus/get-active-joining-bonus', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit
  })
}
export const getActiveReferralBonusService = (data) => {
  return axiosInstance(METHOD_TYPES.get, 'bonus/get-active-referral-bonus', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit
  })
}

export const getTransactions = (data) => {
  return axiosInstance(METHOD_TYPES.get, 'user/transaction-list', {}, {
    server: microServices.USER,
    loader: LOADER_HANDLER_TYPES.submit,
    params: data
  })
}

export const getBonusTransactionsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, 'bonus/list-user-bonus', {}, {
    server: microServices.USER,
    loader: LOADER_HANDLER_TYPES.submit,
    params: data
  })
}

export const generateWalletAddressService = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'cryptopay/generate-address', data, {
    server: microServices.USER,
    loader: LOADER_HANDLER_TYPES.submit

  })
}

export const generateReferralCodeService = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'user/generate-referral-token', data, {
    server: microServices.USER,
    loader: LOADER_HANDLER_TYPES.submit

  })
}

export const depositRequest = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/wallet/deposit-amount', data, {
    server: microServices.USER,
    loader: LOADER_HANDLER_TYPES.submit
  })
}

export const withdrawAmountRequest = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/cryptopay/withdraw-request', data, {
    server: microServices.USER,
    loader: LOADER_HANDLER_TYPES.submit
  })
}

export const generateServerSeedService = () => {
  return axiosInstance(METHOD_TYPES.post, 'user/generate-server-seed', {}, {
    server: microServices.USER_URL
  })
}

export const changeUserPasswordService = (data) => {
  return axiosInstance(METHOD_TYPES.put, 'user/change-password', data, {
    server: microServices.USER,
    loader: LOADER_HANDLER_TYPES.submit
  })
}

export const getUserFeedBack = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'user/create-feedback', data, {
    server: microServices.USER,
    loader: LOADER_HANDLER_TYPES.submit
  })
}

export const createUserPartner = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'user/create-partner', data, {
    server: microServices.USER,
    loader: LOADER_HANDLER_TYPES.submit
  })
}
