import { createSlice } from '@reduxjs/toolkit'
import { getLocalWalletSetting, setLocalWalletSetting } from '../../../utils/common-services/localstorage.services'
import { userLogout, verifyEmail } from '../../redux/thunk/Auth/Auth'
import { fetchUserInformation, getActiveJoiningBonus, getActiveReferralBonus, getBonusTransactions, getUserPlacedBets, getWithdrawRequest, updateProfilePic } from '../../redux/thunk/Users/User'

const initialState = {
  user: null,
  selectedWallet: null,
  redirectToAfterLogin: '',
  transactions: {
    rows: [],
    count: 0
  },
  bonusTransactions: null,
  topBetTransactions: {
    rows: [],
    count: 0
  },
  withdrawRequestData: null,
  loading: false,
  qrCodeURl: null,
  showLoginOtp: false,
  roundData: null,
  showRoundHistoryModel: false,
  hideZeroCurrency: getLocalWalletSetting()?.hideZeroCurrency || false,
  displayFiatCurrency: getLocalWalletSetting()?.displayFiatCurrency || false,
  selectedFiat: getLocalWalletSetting()?.selectedFiat || 'USD',
  activeJoiningBonus: null,
  activeReferralBonus: null,
  emailVerificationError: null,
  serverSeedHashes: null
}
const {
  actions: {
    resetUserStates, setUserData, setQrcodeUrl, setLoader, setSelectedWallet, setRedirectToAfterLogin, setAfterResetPassword, setTransactions, showLoginOtpHandle, setRoundData, setWalletSetting, setTopBetTransactions
  },
  reducer
} = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetUserStates: (state, action) => ({
      ...initialState
    }),
    setUserData: (state, action) => ({
      ...state,
      user: action.payload
    }),
    setQrcodeUrl: (state, action) => ({
      ...state,
      qrCodeURl: action.payload
    }),
    setSelectedWallet: (state, action) => ({
      ...state,
      selectedWallet: action.payload
    }),
    setRedirectToAfterLogin: (state, action) => ({
      ...state,
      redirectToAfterLogin: action.payload
    }),
    setAfterResetPassword: (state, action) => ({
      ...state,
      afterResetPassword: action.payload
    }),
    setLoader: (state, action) => ({
      ...state,
      loading: action.payload
    }),
    showLoginOtpHandle: (state, action) => ({
      ...state,
      showLoginOtp: action.payload
    }),
    setTransactions: (state, action) => ({
      ...state,
      transactions: action.payload
    }),
    setTopBetTransactions: (state, action) => ({
      ...state,
      topBetTransactions: action.payload
    }),
    setRoundData: (state, action) => ({
      ...state,
      roundData: action.payload,
      showRoundHistoryModel: !!action.payload
    }),
    setWalletSetting: (state, action) => {
      setLocalWalletSetting({
        hideZeroCurrency: action.payload.hideZero,
        displayFiatCurrency: action.payload.displayFiat,
        selectedFiat: action.payload.selectedFiatCurrency
      })
      return ({
        ...state,
        hideZeroCurrency: action.payload.hideZero,
        displayFiatCurrency: action.payload.displayFiat,
        selectedFiat: action.payload.selectedFiatCurrency
      })
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserInformation.fulfilled, (state, action) => {
        return {
          ...state,
          user: action.payload.user,
          serverSeedHashes: action.payload.serverSeedHash,
          // selectedWallet: action.payload.user.wallets.find(ele => ele.primary) || action.payload.user.wallets[0],
          loading: false
        }
      })
      .addCase(updateProfilePic.fulfilled, (state, action) => {
        return {
          ...state,
          user: { ...state.user, profileImageUrl: action.payload.location },
          loading: false
        }
      })

      .addCase(updateProfilePic.pending, (state, action) => {
        return {
          ...state,
          loading: true
        }
      })

      .addCase(updateProfilePic.rejected, (state, action) => {
        return {
          ...state,
          loading: false
        }
      })

      .addCase(userLogout.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false
        }
      })

      .addCase(getUserPlacedBets.fulfilled, (state, action) => {
        return {
          ...state,
          transactions: action.payload,
          transactionLoading: false

        }
      })

      .addCase(getUserPlacedBets.pending, (state, action) => {
        return {
          ...state,
          transactions: [],
          transactionLoading: true

        }
      })

      .addCase(getUserPlacedBets.rejected, (state, action) => {
        return {
          ...state,
          transactions: [],
          transactionLoading: false

        }
      })

      .addCase(getWithdrawRequest.fulfilled, (state, action) => {
        return {
          ...state,
          withdrawRequestData: action.payload,
          loading: false
        }
      })

      .addCase(verifyEmail.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false
        }
      })
      .addCase(verifyEmail.rejected, (state, action) => {
        return {
          ...state,
          emailVerificationError: action.payload,
          loading: false
        }
      })
      .addCase(getActiveJoiningBonus.fulfilled, (state, action) => {
        return {
          ...state,
          activeJoiningBonus: action.payload
        }
      })
      .addCase(getActiveReferralBonus.fulfilled, (state, action) => {
        return {
          ...state,
          activeReferralBonus: action.payload
        }
      })
      .addCase(getBonusTransactions.fulfilled, (state, action) => {
        return {
          ...state,
          bonusTransactions: action.payload
        }
      })
  }
})

export default reducer
export {
  resetUserStates, setUserData, setLoader, setSelectedWallet, setRedirectToAfterLogin, setAfterResetPassword, setTransactions, setQrcodeUrl, showLoginOtpHandle, setRoundData, setWalletSetting, setTopBetTransactions
}
