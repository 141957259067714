import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAllChatsService, sendChatService } from '../../../network/services/chat.service'

export const getAllChats = createAsyncThunk('chats/all-Chats', async (params, thunkApi) => {
  try {
    const res = await getAllChatsService(params)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const sendChat = createAsyncThunk('chat/message-sent', async (data, thunkApi) => {
  try {
    const res = await sendChatService(data)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})
