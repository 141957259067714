import { createAsyncThunk } from '@reduxjs/toolkit'
import { minus, plus } from 'number-precision'
import { BONUS_STATUS, BONUS_TYPES } from '../../../utils/constants'
import { claimAccumulatedBonusService, claimBonusService, getAccumulatedBonusService, getAllBonusService, getSplittedBonusService, getTotalRewardsService, settlementCommissionService } from '../../../network/services/bonus.service'
import { setAccumulatedBonus, setAffiliateCommissions, setReferralState, setSplittedBonus } from '../../redux-slices/bonus.slice'

export const getAllBonus = createAsyncThunk(
  'fetch/all-bonus',
  async (data, thunkApi) => {
    try {
      const res = await getAllBonusService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const claimBonus = createAsyncThunk(
  'claim/bonus',
  async (data, thunkApi) => {
    try {
      const { splittedBonus, accumulatedBonus } = thunkApi?.getState()?.bonus
      const res = await claimBonusService(data)
      const splittedBonusKeys = splittedBonus ? Object.keys(splittedBonus?.groupedBonuses) : []
      if (splittedBonusKeys?.length > 0) {
        const updatedSplittedBonus = {}
        splittedBonusKeys?.forEach(key => {
          const updatedList = splittedBonus?.groupedBonuses[key]?.map(bonus =>
            (bonus?.id === res?.userBonus?.id)
              ? { ...bonus, status: BONUS_STATUS.CLAIMED, claimedAt: (new Date())?.toString() }
              : bonus
          )
          updatedSplittedBonus[key] = updatedList
        })
        thunkApi?.dispatch(setSplittedBonus(updatedSplittedBonus))
      }
      thunkApi?.dispatch(setAccumulatedBonus(accumulatedBonus
        ?.map(bonus => {
          const updatedAmount = minus(+bonus?.bonusAmount, +res?.userBonus?.bonusAmount)
          return (bonus?.bonusType === data?.bonusType)
            ? {
                ...bonus,
                status: +updatedAmount > 0 ? BONUS_STATUS.READY_TO_CLAIM : BONUS_STATUS.CLAIMED,
                bonusAmount: updatedAmount,
                claimedAt: (new Date())?.toString()
              }
            : bonus
        }
        )))
      // thunkApi?.dispatch(setUserBonus(userBonus?.userbonusDetails?.map(bonus =>
      //   ((+bonus?.id) === (+res?.userBonus?.id)) ? { ...bonus, status: BONUS_STATUS.CLAIMED, claimedAt: new Date() } : bonus)))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const claimAccumulatedBonus = createAsyncThunk(
  'claim-accumulated-bonus',
  async (data, thunkApi) => {
    try {
      const { accumulatedBonus, splittedBonus } = thunkApi?.getState()?.bonus
      const res = await claimAccumulatedBonusService(data)
      const splittedBonusKeys = splittedBonus ? Object.keys(splittedBonus?.groupedBonuses) : []
      if (splittedBonusKeys?.length > 0) {
        const updatedSplittedBonus = {}
        splittedBonusKeys?.forEach(key => {
          const updatedList = splittedBonus?.groupedBonuses[key]?.map(bonus => {
            const bonusType = (bonus?.bonusType === BONUS_TYPES.WEEKLYSPLITTED) ? BONUS_TYPES.WEEKLY : bonus?.bonusType
            if ((bonusType === data?.bonusType) && (bonus?.status === BONUS_STATUS.READY_TO_CLAIM)) {
              return { ...bonus, status: BONUS_STATUS.CLAIMED, claimedAt: (new Date())?.toString() }
            }
            return bonus
          }
          )
          updatedSplittedBonus[key] = updatedList
        })
        thunkApi?.dispatch(setSplittedBonus(updatedSplittedBonus))
      }
      thunkApi?.dispatch(setAccumulatedBonus(accumulatedBonus
        ?.map(bonus =>
          (bonus?.bonusType === data?.bonusType)
            ? { ...bonus, status: BONUS_STATUS.CLAIMED, bonusAmount: 0, claimedAt: (new Date())?.toString() }
            : bonus)))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getTotalRewards = createAsyncThunk(
  'total/reward',
  async (data, thunkApi) => {
    try {
      const res = await getTotalRewardsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getSplittedBonus = createAsyncThunk(
  'fetch-splitted-bonus-details',
  async (params, thunkApi) => {
    try {
      const res = await getSplittedBonusService(params)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const settlementCommission = createAsyncThunk(
  'settlement-commission', async (_, thunkApi) => {
    try {
      const res = await settlementCommissionService()
      const { referralState } = thunkApi?.getState()?.bonus
      thunkApi?.dispatch(setReferralState(
        {
          affiliatesProfitCommision: plus(
            referralState?.affiliatesProfitCommision,
            referralState?.affiliatesPendingCommision),
          affiliatesPendingCommision: 0
        }))
      thunkApi?.dispatch(setAffiliateCommissions())
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  })

export const getAccumulatedBonus = createAsyncThunk(
  'fetch-accumulated-bonus-details',
  async (_, thunkApi) => {
    try {
      const res = await getAccumulatedBonusService()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
