import React, { useEffect } from 'react'
import './statistics.scss'
import { useDispatch, useSelector } from 'react-redux'
import { formatPrice } from '../../utils/helper'
import { getTotalRewards } from '../../redux-store/redux/thunk/bonus.thunk'

const Statistics = ({ setStatisticPopup }) => {
    const dispatch = useDispatch()
    const { totalRewards } = useSelector(state => state.bonus)

    useEffect(() => {
        dispatch(getTotalRewards())
    }, [])
    return (
        <div className='modal fade show' id='wallet-modal' style={{ display: 'block' }}>
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content'>
                    <div className='modal-header py-3 border-0 px-4'>
                        <h5 className='modal-title fs-5 text-white ms-2'>Statistics</h5>
                        <button
                            type='button'
                            className='btn-close btn-close-white small'
                            onClick={() => {
                                setStatisticPopup(false)
                            }}
                        />
                    </div>
                    <div className='modal-body'>
                        <div className='reward-statistic-page'>
                            <div className='reward-data'>
                                <div className='reward-top-row'>
                                    <div>
                                        <h3>Total Wins</h3>
                                        <p>${formatPrice((+totalRewards?.totalWins)) || '0.00'}</p>
                                    </div>
                                    <div>
                                        <h3>Total Bets</h3>
                                        <p>{(+totalRewards?.totalBets) || '0'}</p>
                                    </div>
                                </div>
                                <div>
                                    <h3>Total Wagered</h3>
                                    <p>${formatPrice((+totalRewards?.totalWagered)) || '0.00'}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Statistics
