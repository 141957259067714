// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-dialog {
  min-width: 700px; }

.reward-bonus-page {
  margin-top: 2rem;
  display: flex;
  color: #ffffff; }
  .reward-bonus-page .bonus-data h3 {
    font-size: 1.25rem; }
  .reward-bonus-page .bonus-data .bonus-row {
    display: flex;
    margin-top: 2.5rem; }
    .reward-bonus-page .bonus-data .bonus-row div {
      margin-right: 4rem; }
`, "",{"version":3,"sources":["webpack://./src/components/Bonus/bonus.scss"],"names":[],"mappings":"AACA;EACE,gBAAgB,EAAA;;AAElB;EACE,gBAAgB;EAChB,aAAa;EACb,cAAc,EAAA;EAHhB;IAQM,kBAAkB,EAAA;EARxB;IAYM,aAAa;IACb,kBAAkB,EAAA;IAbxB;MAgBQ,kBAAkB,EAAA","sourcesContent":["\n.modal-dialog {\n  min-width: 700px;\n}\n.reward-bonus-page {\n  margin-top: 2rem;\n  display: flex;\n  color: #ffffff;\n\n  .bonus-data{\n\n    h3 {\n      font-size: 1.25rem;\n    }\n    \n    .bonus-row {\n      display: flex;\n      margin-top: 2.5rem;\n\n      div {\n        margin-right: 4rem;\n      }\n    }\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
