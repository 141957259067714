import { METHOD_TYPES } from '../../utils/constants'
import axiosInstanceService, { microServices } from '../apis'

export const getAllChatsService = (params) => {
  return axiosInstanceService(METHOD_TYPES.get, '/atomic-roulette/chat', {}, {
    params,
    server: microServices.USER_URL
  })
}

export const sendChatService = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, '/atomic-roulette/send-message', data, {
    server: microServices.USER_URL
  })
}
