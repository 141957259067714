import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  showSideNav: true,
  showSideBetList: false,
  showLandingPage: false, // typeof localStorage.getItem('hasVisited') === 'undefined' ? true : !localStorage.getItem('hasVisited')
  showSearchPopup: false,
  showNotification: false
}

const {
  actions: {
    setShowSideNav,
    setShowSideBetList,
    setShowNotification,
    setShowLandingPage,
    setShowSearchPopup
  },
  reducer
} = createSlice({
  name: 'settings',
  initialState: initialState,
  reducers: {
    setShowLandingPage: (state, { payload }) => ({
      ...state,
      showLandingPage: payload
    }),
    setShowSideNav: (state, { payload }) => ({
      ...state,
      showSideNav: payload
    }),
    setShowSideBetList: (state, { payload }) => ({
      ...state,
      showSideBetList: payload
    }),
    setShowNotification: (state, { payload }) => ({
      ...state,
      showNotification: payload
    }),
    setShowSearchPopup: (state, { payload }) => ({
      ...state,
      showSearchPopup: payload
    })
  }
})

export default reducer
export { setShowSideNav, setShowSideBetList, setShowLandingPage, setShowSearchPopup, setShowNotification }
