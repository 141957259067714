import { IS_MP4_STREAM, MP4_LINK, OPERATOR_ID, ROLE, TOKEN, ZEGO_CLOUD_TOKEN } from '../../utils/constants/index'
import Cookies from 'universal-cookie'
const cookies = new Cookies()

export const getAuthToken = () => {
  const tokenString = window.sessionStorage.getItem(TOKEN)
  const userToken = JSON.parse(tokenString)
  return userToken
}

export const setAuthToken = userToken => {
  window.sessionStorage.setItem(TOKEN, JSON.stringify(userToken))
}
export const getZegoCloudToken = () => {
  const tokenString = window.sessionStorage.getItem(ZEGO_CLOUD_TOKEN)
  const userToken = JSON.parse(tokenString)
  return userToken
}

export const setZegoCloudToken = userToken => {
  window.sessionStorage.setItem(ZEGO_CLOUD_TOKEN, JSON.stringify(userToken))
}

export const removeZegoCloudToken = userToken => {
  sessionStorage.removeItem(ZEGO_CLOUD_TOKEN)
}

export const removeStreamingDetails = userToken => {
  sessionStorage.removeItem(MP4_LINK)
  sessionStorage.removeItem(IS_MP4_STREAM)
}

// 24 hour * 60 minutes of every hour
const COOKIE_EXPIRE_MIN = 24 * 60

/* ==========================================================================
  Auth Token
========================================================================== */
// export const getAuthToken = () => {
//   return cookies.get(TOKEN)
// }

// export const setAuthToken = (authAccessToken) => {
//   cookies.set(TOKEN, authAccessToken, {
//     path: '/',
//     expires: new Date((Date.now() + COOKIE_EXPIRE_MIN * 60 * 1000))
//   })

// }

export const removeAuthToken = () => {
  // cookies.remove(TOKEN, { path: '/' })
  sessionStorage.removeItem(TOKEN)
}

export const removeMaxBtn = () => {
  // cookies.remove(TOKEN, { path: '/' })
  localStorage.removeItem('is-max-btn-enable')
}

/* ==========================================================================
  Operator ID
========================================================================== */
export const getOperatorId = () => {
  return cookies.get(OPERATOR_ID)
}

export const setOperatorId = (operatorId) => {
  cookies.set(OPERATOR_ID, operatorId, {
    path: '/',
    expires: new Date((Date.now() + COOKIE_EXPIRE_MIN * 60 * 1000))
  })
}

export const removeOperatorId = () => {
  cookies.remove(OPERATOR_ID, { path: '/' })
}

/* ==========================================================================
  Operator ID
========================================================================== */
export const getOperatorRole = () => {
  return cookies.get(ROLE)
}

export const setOperatorRole = (role) => {
  cookies.set(ROLE, role, {
    path: '/',
    expires: new Date((Date.now() + COOKIE_EXPIRE_MIN * 60 * 1000))
  })
}

export const removeOperatorRole = () => {
  cookies.remove(ROLE, { path: '/' })
}

/* ==========================================================================
  Signin Action
========================================================================== */
export const signIn = ({ token, operatorId, role }) => {
  setAuthToken(token)
  // setOperatorId(operatorId)
  // setOperatorRole(role)
}

/* ==========================================================================
  Signout Action
========================================================================== */
export const signOut = () => {
  removeAuthToken()
  removeMaxBtn()
  removeZegoCloudToken()
  removeStreamingDetails()
  // removeOperatorId()
  // removeOperatorRole()
}
