// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reward-profit-page {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  color: #ffffff; }
  .reward-profit-page .profit-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; }
    .reward-profit-page .profit-detail .profit-vip-status {
      display: flex;
      align-items: center; }
      .reward-profit-page .profit-detail .profit-vip-status .progress-bar-container {
        width: 200px;
        /* Adjust width as needed */
        height: 20px;
        background-color: #222;
        /* Dark background color */
        border-radius: 10px;
        position: relative;
        margin: 0 1rem; }
      .reward-profit-page .profit-detail .profit-vip-status .progress-bar {
        height: 100%;
        background-color: var(--secondaryButtonColor);
        /* Progress bar color */
        border-radius: 10px;
        position: absolute;
        left: 0;
        top: 0;
        width: 50%;
        /* Adjust width for initial progress */
        transition: width 0.3s ease;
        /* Smooth animation */ }
      .reward-profit-page .profit-detail .profit-vip-status p {
        margin: 0; }
`, "",{"version":3,"sources":["webpack://./src/components/MyProfile/myProfile.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EAEb,8BAA8B;EAC9B,kBAAkB;EAClB,cAAc,EAAA;EALlB;IAQM,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,WAAW,EAAA;IAXjB;MAcQ,aAAa;MACb,mBAAmB,EAAA;MAf3B;QAkBU,YAAY;QAAE,2BAAA;QACd,YAAY;QACZ,sBAAsB;QAAE,0BAAA;QACxB,mBAAmB;QACnB,kBAAkB;QAClB,cAAc,EAAA;MAvBxB;QA2BU,YAAY;QACZ,6CAA6C;QAAE,uBAAA;QAC/C,mBAAmB;QACnB,kBAAkB;QAClB,OAAO;QACP,MAAM;QACN,UAAU;QAAE,sCAAA;QACZ,2BAA2B;QAAE,qBAAA,EAAsB;MAlC7D;QAsCU,SAAS,EAAA","sourcesContent":[".reward-profit-page {\n    display: flex;\n    // width: 50%;\n    justify-content: space-between;\n    margin-top: 1.5rem;\n    color: #ffffff;\n\n    .profit-detail {\n      display: flex;\n      flex-direction: column;\n      align-items: center;\n      width: 100%;\n\n      .profit-vip-status {\n        display: flex;\n        align-items: center;\n\n        .progress-bar-container {\n          width: 200px; /* Adjust width as needed */\n          height: 20px;\n          background-color: #222; /* Dark background color */\n          border-radius: 10px;\n          position: relative;\n          margin: 0 1rem;\n        }\n\n        .progress-bar {\n          height: 100%;\n          background-color: var(--secondaryButtonColor); /* Progress bar color */\n          border-radius: 10px;\n          position: absolute;\n          left: 0;\n          top: 0;\n          width: 50%; /* Adjust width for initial progress */\n          transition: width 0.3s ease; /* Smooth animation */\n        }\n\n        p {\n          margin: 0;\n        }\n      }\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
