import React, { useCallback, useState, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { yupResolver } from '@hookform/resolvers/yup'
import ErrorMessage from '../ErrorMessage'
import Loader from '../Loader'
import { LOADER } from '../../utils/constants'

const LoginPopupContent = ({ id, handleLoginSubmit, loading, handleRedirectToTnC }) => {
  const { t } = useTranslation('home')
  const userNameOrEmailRef = useRef(null) // Create a ref for the input field
  const [showPassword, setShowPassword] = useState(false)

  const loginValidationSchema = yup.object().shape({
    userNameOrEmail: yup
      .string()
      .required(`${t('signin.errors.userName.required')}`),
    password: yup
      .string()
      .required(`${t('signin.errors.password.required')}`)
      .min(8, `${t('signin.errors.password.minLength')}`)
    // .max(16, `${t('signin.errors.password.maxLength')}`)
  })

  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(loginValidationSchema),
    mode: 'all'
  })

  useEffect(() => {
    if (userNameOrEmailRef.current) {
      userNameOrEmailRef.current.focus() // Set focus on the input field
    }
  }, [userNameOrEmailRef.current])

  useEffect(() => {
    const modalElement = document.getElementById(id)
    const modalHideHandler = () => {
      reset() // Reset the form state when the modal is hidden
    }

    modalElement.addEventListener('hide.bs.modal', modalHideHandler)

    return () => {
      modalElement.removeEventListener('hide.bs.modal', modalHideHandler)
    }
  }, [reset])

  const handleShowPassword = useCallback(() => {
    setShowPassword((prev) => !prev)
  }, [showPassword, setShowPassword])

  useEffect(() => {
    // Listen for autofill events and update form values
    const inputs = document.querySelectorAll('input')

    inputs.forEach((input) => {
      input.addEventListener('input', () => {
        setValue(input.name, input.value, {
          shouldValidate: true, // Re-validate the field
          shouldDirty: true // Mark the field as dirty
        })
      })
    })

    // Cleanup listeners on unmount
    return () => {
      inputs.forEach((input) =>
        input.removeEventListener('input', () => {})
      )
    }
  }, [setValue])

  return (
    <form onSubmit={handleSubmit(handleLoginSubmit)}>
      <div className='row justify-content-center auth-modal-form'>
        <div className='disabled'>
          <div className='col-sm-12'>
            <div className='mb-3'>
              <label className='form-label'>
                {t('signin.username')} <span className='text-danger'>*</span>
              </label>
              <div className='input-group'>
                <input
                  ref={userNameOrEmailRef}
                  type='text'
                  className='form-control'
                  name='userNameOrEmail'
                  maxLength={50}
                  autoComplete='off'
                  {...register('userNameOrEmail')}
                />
              </div>
              {errors && errors.userNameOrEmail && (
                <ErrorMessage
                  className='text-danger error-msg login-input small'
                  message={errors.userNameOrEmail.message}
                />
              )}
            </div>
          </div>
          <div className='col-sm-12'>
            <div className='mb-3'>
              <label className='form-label'>
                {t('signin.password')} <span className='text-danger'>*</span>
              </label>
              <div className='input-group'>
                <input
                  id='login-password'
                  type={showPassword ? 'text' : 'password'}
                  className='form-control pe-5'
                  name='password'
                  // maxLength={50}
                  autoComplete='off'
                  {...register('password')}
                />
                <span className='addon addon-right cursor-pointer'>
                  <button
                    type='button'
                    className='input-btn'
                    onClick={handleShowPassword}
                  >
                    {showPassword
                      ? (
                        <FontAwesomeIcon icon={faEyeSlash} />
                        )
                      : (
                        <FontAwesomeIcon icon={faEye} />
                        )}
                  </button>
                </span>
              </div>
              {errors && errors.password && (
                <ErrorMessage
                  className='text-danger error-msg login-input small'
                  message={errors.password.message}
                />
              )}
            </div>
          </div>
          <div className='col-sm-12'>
            <div className='btn-box mt-2'>
              <button type='submit' className='btn secondary-btn-color w-100' disabled={loading}>
                {loading
                  ? (
                    <Loader component={LOADER.BUTTON} />
                    )
                  : (
                      t('signin.loginBtn')
                    )}
              </button>
            </div>
          </div>
        </div>
        <div className='col-sm-12'>
          <div className='text-center mb-2 mt-4'>
            <button
              type='button'
              className='btn text-btn m-auto'
              data-bs-target='#forgotPasswordModal'
              data-bs-toggle='modal'
              data-bs-dismiss='modal'
            >
              {t('signin.forgetPassword')}
            </button>
          </div>
        </div>
        <div className='col-sm-12'>
          <p className='pera-text mb-2'>
            <span className='mx-1'>{t('signin.dontHaveAccount')}</span>
            <button
              type='button'
              className='btn text-btn'
              data-bs-target='#registerModal'
              data-bs-toggle='modal'
              data-bs-dismiss='modal'
            >
              {t('signin.createAccount')}
            </button>
          </p>
          <p className='pera-text mb-2'>
            <span className='mx-1'>{t('signin.loginDescription')}</span>
            <button type='button' className='btn text-btn' onClick={handleRedirectToTnC}>
              {t('signin.privacyPolicyBtn')}
            </button>
            <span className='mx-1'>and</span>
            <button type='button' className='btn text-btn' onClick={handleRedirectToTnC}>
              Terms of Service
            </button>
            <span className='mx-1'>apply</span>
          </p>
        </div>
      </div>
    </form>
  )
}

export default LoginPopupContent
