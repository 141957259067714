import { createSlice } from '@reduxjs/toolkit'
import { DEFAULT_PAGE_CALLS } from '../../../utils/constants'
import { getWinningBetTypes } from '../../../containers/AHOriginalsGames/Roulette/RouletteGame/game-helpers'
import { getLiveStreamingListing, getUserTopBets, getRoundTopWins, placedBetAtomicRouletteGame } from '../../redux/thunk/Games/atomicRouletteGame'
import { getMyBetsRoulette } from '../../redux/thunk/Games/rouletteGame'

const initialState = {
  placedBetData: null,
  betLock: true,
  betLoading: false,
  myRouletteBetsData: { count: 0, rows: [] },
  myRouletteBetsLoading: false,
  showWin: false,
  recentWinning: null,
  winTypesContent: null,
  netProfitLoss: 0,
  defaultSetting: null,
  liveStreamingListing: null,
  liveStreamingListingLoading: true,
  currentRouletteRound: [],
  recentRoundResults: [],
  atomicUserTopBets: null,
  atomicUserTopWins: null
}

const {
  actions: {
    setBetLockTrue,
    setBetLockFalse,
    setRouletteRoundEnd,
    appendMyBetsRoulette,
    resetNetProfitLoss,
    setDefaultSetting,
    setRecentWinnings,
    setCurrentRouletteRound,
    setAtomicRouletteRoundResults
  },
  reducer
} = createSlice({
  name: 'atomicRouletteGame',
  initialState,
  reducers: {
    setBetLockTrue: (state, action) => {
      return {
        ...state,
        betLock: true
      }
    },
    setBetLockFalse: (state, action) => {
      return {
        ...state,
        betLock: false
      }
    },
    setRouletteRoundEnd: (state, action) => {
      return {
        ...state,
        showWin: true,
        betLock: false,
        recentWinning: [state.placedBetData, ...state.recentWinning].slice(0, 4)
      }
    },
    appendMyBetsRoulette: (state, action) => {
      const { nextServerSeedHash, ...data } = state.placedBetData || { nextServerSeedHash: '', data: {} }
      if (nextServerSeedHash) {
        const myBetsDataRows = [data, ...state.myRouletteBetsData?.rows]
        if (myBetsDataRows.length > DEFAULT_PAGE_CALLS) {
          myBetsDataRows.pop()
        }
        return {
          ...state,
          myRouletteBetsData: {
            count: state.myRouletteBetsData?.count + 1,
            rows: myBetsDataRows
          }
        }
      }
      return state
    },
    resetNetProfitLoss: (state, action) => ({
      ...state,
      netProfitLoss: 0
    }),
    setDefaultSetting: (state, action) => ({
      ...state,
      defaultSetting: action.payload
    }),
    setRecentWinnings: (state, action) => {
      const recentWinning = [...state.recentWinning, action.payload]
      return {
        ...state,
        recentWinning: recentWinning
      }
    },
    setCurrentRouletteRound: (state, action) => {
      return {
        ...state,
        currentRouletteRound: action.payload
      }
    },
    setAtomicRouletteRoundResults: (state, action) => {
      return {
        ...state,
        recentRoundResults: [...state.recentRoundResults, action.payload]
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(placedBetAtomicRouletteGame.pending, (state, action) => {
        return {
          ...state,
          showWin: false,
          betLoading: true,
          winTypesContent: null
        }
      })
      .addCase(placedBetAtomicRouletteGame.fulfilled, (state, action) => {
        const { winningNumber, betAmount, winningAmount } = action.payload || { winningNumber: 0 }

        const winTypesContent = getWinningBetTypes(winningNumber)
        const newNetGain = state.netProfitLoss + parseFloat(winningAmount) - parseFloat(betAmount)
        return {
          ...state,
          winTypesContent,
          placedBetData: action.payload,
          betLoading: false,
          netProfitLoss: newNetGain
        }
      })
      .addCase(placedBetAtomicRouletteGame.rejected, (state, action) => {
        return {
          ...state,
          betLock: false,
          betLoading: false
        }
      })
      .addCase(getMyBetsRoulette.pending, (state, action) => {
        return {
          ...state,
          myRouletteBetsData: action.payload,
          myRouletteBetsLoading: true,
          betsLoading: true
        }
      })
      .addCase(getMyBetsRoulette.fulfilled, (state, action) => {
        return {
          ...state,
          myRouletteBetsData: action.payload,
          myRouletteBetsLoading: false,
          betsLoading: false
        }
      })
      .addCase(getMyBetsRoulette.rejected, (state, action) => {
        return {
          ...state,
          myRouletteBetsLoading: false,
          betsLoading: false
        }
      })
      .addCase(getLiveStreamingListing.pending, (state, action) => {
        return {
          ...state,
          liveStreamingListingLoading: true
        }
      })
      .addCase(getLiveStreamingListing.fulfilled, (state, action) => {
        return {
          ...state,
          liveStreamingListingLoading: false,
          liveStreamingListing: action?.payload
        }
      })
      .addCase(getLiveStreamingListing.rejected, (state, action) => {
        return {
          ...state,
          liveStreamingListingLoading: true
        }
      })
      .addCase(getUserTopBets.fulfilled, (state, action) => {
        return {
          ...state,
          atomicUserTopBets: action?.payload
        }
      })
      .addCase(getRoundTopWins.fulfilled, (state, action) => {
        return {
          ...state,
          atomicUserTopWins: action?.payload
        }
      })
  }
})

export {
  setBetLockTrue,
  setBetLockFalse,
  setRouletteRoundEnd,
  appendMyBetsRoulette,
  resetNetProfitLoss,
  setDefaultSetting,
  setRecentWinnings,
  setCurrentRouletteRound,
  setAtomicRouletteRoundResults
}

export default reducer
