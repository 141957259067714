import { createSlice } from '@reduxjs/toolkit'
import { getAccumulatedBonus, getAllBonus, getSplittedBonus, getTotalRewards } from '../redux/thunk/bonus.thunk'

const initialState = {
  allBonus: null,
  totalRewards: null,
  splittedBonus: { groupedBonuses: {} },
  accumulatedBonus: null,
  referralState: null,
  affiliateCommissions: null
}

const {
  actions: {
    setSplittedBonus,
    setAccumulatedBonus,
    setReferralState,
    setAffiliateCommissions
  },
  reducer
} = createSlice({
  name: 'bonus',
  initialState,
  reducers: {
    setSplittedBonus: (state, action) => ({
      ...state,
      splittedBonus: { groupedBonuses: action.payload }
    }),
    setAccumulatedBonus: (state, action) => ({
      ...state,
      accumulatedBonus: action.payload
    }),
    setReferralState: (state, action) => {
      return {
        ...state,
        referralState: { ...state.referralState, ...action.payload }
      }
    },
    setAffiliateCommissions: (state, action) => {
      return {
        ...state,
        affiliateCommissions: {
          ...state?.affiliateCommissions,
          rows: state?.affiliateCommissions
            ?.rows?.map(data => ({
              ...data,
              status: 'completed'
            }))
        }
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllBonus.fulfilled, (state, action) => {
        return {
          ...state,
          allBonus: action.payload
        }
      })
      .addCase(getTotalRewards.fulfilled, (state, action) => {
        return {
          ...state,
          totalRewards: action.payload
        }
      })
      .addCase(getSplittedBonus.fulfilled, (state, action) => {
        return {
          ...state,
          splittedBonus: action.payload
        }
      })
      .addCase(getAccumulatedBonus.fulfilled, (state, action) => {
        return {
          ...state,
          accumulatedBonus: action.payload
        }
      })
  }
})

export default reducer
export {
  setSplittedBonus,
  setAccumulatedBonus,
  setReferralState,
  setAffiliateCommissions
}
