import { io } from 'socket.io-client'
// import customParser from 'socket.io-msgpack-parser'

const useCurrentGameAllBetsSocket = (currentNamespace) => {
  const listenCurrentGameAllBets = (cb) => {
    const walletSocketInstance = io(`${process.env.REACT_APP_BACKEND_URL_WS}${currentNamespace}`, {
    // parser: customParser,
      transport: ['websocket'],
      reconnectionAttempts: 1
    })
    if (cb) {
      walletSocketInstance.on(`${currentNamespace}/getBets`, cb)
    }
    return () => {
      walletSocketInstance.off(`${currentNamespace}/getBets`, cb)
    }
  }

  return listenCurrentGameAllBets
}

export default useCurrentGameAllBetsSocket
