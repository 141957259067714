import { LOADER_HANDLER_TYPES, METHOD_TYPES } from '../../utils/constants'
import axiosInstanceService, { microServices } from '../apis'

export const getAnnouncementsService = (params) => {
  return axiosInstanceService(METHOD_TYPES.get, '/system/announcement', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.userDetails,
    params
  })
}
