import moment from 'moment';
import React from 'react'

const RolloverDetails = ({ data, setRolloverModal }) => {
  return (
    <div className='modal fade show' id='wallet-modal' style={{ display: 'block' }}>
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header py-3 border-0 px-4'>
            <h5 className='modal-title fs-5 text-white ms-2'>Rollover Details</h5>
            <button
              type='button'
              className='btn-close btn-close-white small'
              onClick={() => {
                setRolloverModal(false)
              }}
            />
          </div>
          <div className='modal-body'>
            <div className='nav-pills mb-3 mx-auto' id='pills-tab' role='tablist'>
              <div className='nav-item'>
                <span>Status: </span>
                <span>{data?.status}</span>
              </div>
              <div className='nav-item'>
                <span>Type: </span>
                <span>{data?.bonusType}</span>
              </div>
              <div className='nav-item'>
                <span>Created on: </span>
                <span>{moment(data?.updatedAt).format('MMM DD YYYY, h:mm:ss a')}</span>
              </div>
              <div className='nav-item'>
                <span>Wager Amount: </span>
                <span>{data?.wageredAmount}</span>
              </div>
              <div className='nav-item'>
                <span>Wagering Status: </span>
                <span>{data?.wageringStatus}</span>
              </div>
            </div>
            <div className='tab-content' id='pills-tabContent'>
              {/* <Deposit setShowWalletPopup={setShowWalletPopup} />
              <Withdraw setShowWalletPopup={setShowWalletPopup} /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RolloverDetails
