/**
 * Generate a random card number between [1,52]
 * @returns {number}
 */
export const randomCardGenerate = () => {
  return Math.floor(Math.random() * 52) + 1
}

export const generateClientSeed = () => {
  const availableChars = '0123456789abcdef'
  let seed = ''
  for (let i = 0; i < 32; i++) {
    seed +=
          availableChars[Math.floor(Math.random() * availableChars.length)]
  }
  return seed
}

export const cartesianProductOfArrays = (...a) => a.reduce((a, b) => a.flatMap(d => b.map(e => [d, e].flat())))

export const getPayloadFromToken = (token) => {
  if (token) {
    return JSON.parse(atob(token.split('.')[1]))
  }
}

export const getLiveStreamingId = () => {
  const pathParts = window.location.pathname.split('/')
  if (pathParts) {
    return pathParts[5]
  }
  return null
}
export const getHostId = () => {
  const pathParts = window.location.pathname.split('/')
  if (pathParts) {
    return pathParts[4]
  }
  return null
}
