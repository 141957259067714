import { METHOD_TYPES } from '../../utils/constants/index'
import axiosInstance, { microServices } from '../apis'

export const getGameIframeUrlService = (params) => {
  return axiosInstance(METHOD_TYPES.get, '/system/start-game', {}, {
    server: microServices.USER_URL,
    params
  })
}

export const getCasinoProvidersService = (params) => {
  return axiosInstance(METHOD_TYPES.get, '/system/get-providers', {}, {
    server: microServices.USER_URL,
    params
  })
}

export const getGamesListService = (params) => {
  return axiosInstance(METHOD_TYPES.get, '/system/games', {}, {
    server: microServices.USER_URL,
    params
  })
}

export const getCasinoAllCategoriesService = () => {
  return axiosInstance(METHOD_TYPES.get, '/system/category', {}, {
    server: microServices.USER_URL
  }
  )
}

export const getUsersCasinoTransactionsService = (params) => {
  return axiosInstance(METHOD_TYPES.get, '/system/casino-transaction', {}, {
    server: microServices.USER_URL,
    params
  })
}

export const addFavoriteGameService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/system/add-favourite-game', data, {
    server: microServices.USER_URL
    // loader: LOADER_HANDLER_TYPES.submit,
  })
}

export const removeFavouriteGameService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/system/remove-favourite-game', data, {
    server: microServices.USER_URL
    // loader: LOADER_HANDLER_TYPES.submit,
  })
}

export const getAllFavoriteGamesService = (params) => {
  return axiosInstance(METHOD_TYPES.get, '/system/get-favourite-game', {}, {
    server: microServices.USER_URL,
    params
  })
}
export const addCustomFavouriteGameService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/system/add-custom-favourite-game', data, {
    server: microServices.USER_URL
    // loader: LOADER_HANDLER_TYPES.submit,
  })
}

export const removeCustomFavouriteGameService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/system/remove-custom-favourite-game', data, {
    server: microServices.USER_URL
    // loader: LOADER_HANDLER_TYPES.submit,
  })
}

// export const getAllFavoriteGamesService = (params) => {
//   return axiosInstance(METHOD_TYPES.get, '/system/get-favourite-game', {}, {
//     server: microServices.USER_URL,
//     params
//   })
// }

export const getCustomGameTransactionsService = (params) => {
  return axiosInstance(METHOD_TYPES.get, '/system/all-bets', {}, {
    server: microServices.USER_URL,
    params
  })
}

export const getMyCustomGameTransactionsService = (params) => {
  return axiosInstance(METHOD_TYPES.get, '/system/my-bets', {}, {
    server: microServices.USER_URL,
    params
  })
}

export const getCustomGameHighRollerTransactionsService = (params) => {
  return axiosInstance(METHOD_TYPES.get, '/system/top-bets', {}, {
    server: microServices.USER_URL,
    params
  })
}

export const getCasinoTransactionsService = (params) => {
  return axiosInstance(METHOD_TYPES.get, '/system/casino-transaction', {}, {
    server: microServices.USER_URL,
    params
  })
}

export const getSportsBookTransactionsService = (params) => {
  return axiosInstance(METHOD_TYPES.get, '/system/sports-book-transactions', {}, {
    server: microServices.USER_URL,
    params
  })
}

export const getAllCustomFavouriteGamesService = (params) => {
  return axiosInstance(METHOD_TYPES.get, '/common-game/custom-favourite', {}, {
    server: microServices.USER_URL,
    params
  })
}

export const getRecentlyPlayedGamesService = (params) => {
  return axiosInstance(METHOD_TYPES.get, '/system/get-recent-played-games', {}, {
    server: microServices.USER_URL,
    params
  })
}
