import { createSlice } from '@reduxjs/toolkit'
import { getAllCasinoGameFromProvider, getAllCasinoCategory, launchCasinoGame, getAllCasinoPageGames } from '../../redux/thunk/Games/casinoGame'
import { getCasinoProvidersList } from '../../redux/thunk/game'

const initialState = {
  casinoGameList: [],
  casinoCategories: [],
  casinoLaunchData: null,
  casinoListLoading: false,
  selectedCasinoGame: null,
  casinoPageGamesList: { rows: [], count: 0 },
  casinoGamePageLoading: false,
  selectedCasinoGameType: 'All',
  subCasinoGamePages: false,
  providers: null
}

const {
  actions: {
    resetCasinoLaunchData,
    setSelectedCasinoGame,
    setSelectedCasinoGameType
  },
  reducer
} = createSlice({
  name: 'casinoGame',
  initialState,
  reducers: {
    resetCasinoLaunchData: (state, action) => ({
      ...state,
      casinoLaunchData: null,
      selectedCasinoGame: null
    }),
    setSelectedCasinoGame: (state, action) => ({
      ...state,
      selectedCasinoGame: action.payload
    }),
    setSelectedCasinoGameType: (state, action) => ({
      ...state,
      selectedCasinoGameType: action.payload
    })
  },
  extraReducers: builder => {
    builder
      .addCase(getAllCasinoGameFromProvider.pending, (state, { payload }) => {
        return ({
          ...state,
          casinoListLoading: true
        })
      })
      .addCase(getAllCasinoGameFromProvider.fulfilled, (state, { payload }) => {
        const { params, res } = payload
        const currentCategory = params?.gameCategory || res?.rows?.[0]?.category?.gameCategory || ''
        if (currentCategory) {
          const updatedCasinoGameList = { ...state.casinoGameList }
          if (params.offset === 0) {
            updatedCasinoGameList[currentCategory] = res
            return ({
              ...state,
              casinoGameList: updatedCasinoGameList,
              casinoListLoading: false
            })
          } else {
            updatedCasinoGameList[currentCategory] = { count: res.count, rows: [...updatedCasinoGameList[currentCategory]?.rows, ...res?.rows] }
            return ({
              ...state,
              casinoGameList: updatedCasinoGameList,
              casinoListLoading: false
            })
          }
        }
        return ({
          ...state,
          casinoListLoading: false
        })
      })
      .addCase(getAllCasinoGameFromProvider.rejected, (state, { payload }) => {
        return ({
          ...state,
          casinoListLoading: false
        })
      })
      .addCase(getAllCasinoPageGames.pending, (state, { payload }) => {
        return ({
          ...state,
          casinoGamePageLoading: true
        })
      })
      .addCase(getAllCasinoPageGames.fulfilled, (state, { payload }) => {
        const { data: params, res } = payload
        let updatedCasinoGamePageList = { ...state.casinoPageGamesList }
        if (params.offset === 0) {
          updatedCasinoGamePageList = res
          return ({
            ...state,
            casinoPageGamesList: updatedCasinoGamePageList,
            casinoGamePageLoading: false
          })
        } else {
          updatedCasinoGamePageList = { count: res.count, rows: [...updatedCasinoGamePageList?.rows, ...res?.rows] }
          return ({
            ...state,
            casinoPageGamesList: updatedCasinoGamePageList,
            casinoGamePageLoading: false
          })
        }
      })
      .addCase(getAllCasinoPageGames.rejected, (state, { payload }) => {
        return ({
          ...state,
          casinoGamePageLoading: false
        })
      })
      .addCase(getAllCasinoCategory.fulfilled, (state, { payload }) => {
        return ({
          ...state,
          casinoCategories: payload?.rows?.sort((a, b) => a.id - b.id)
        })
      })
      .addCase(launchCasinoGame.fulfilled, (state, { payload }) => ({
        ...state,
        casinoLaunchData: payload
      }))
      .addCase(getCasinoProvidersList.fulfilled, (state, action) => {
        return {
          ...state,
          providers: action?.payload
        }
      })
  }
})

export {
  resetCasinoLaunchData,
  setSelectedCasinoGame,
  setSelectedCasinoGameType
}

export default reducer
